require('./initLocalStorage');

require('./initTheme');

window.acceptGdpr = require('./acceptGdpr');
window.Modal = require('./modal');
require('./unready');
window.fontTest = require('./fontTest');
window.showTopNotification = require('./showTopNotification');

require('./sponsorBar');
require('../videoPlayerModal');