const delegate = require('client/delegate');

class VideoPlayerModal extends Modal {

  constructor(options = {}) {
    super(options);
    this.options.inModal = true;

    this.setContent(`
    <video controls autoplay style="max-width:100%;max-height:100%">
      <source src="${options.src}" type="video/mp4"/>
    </video>
    `);
  }

  render() {
    super.render();
    this.elem.classList.add('video-player-modal');
  }

}

delegate.delegateMixin(VideoPlayerModal.prototype);

module.exports = VideoPlayerModal;
