// Also see layout.js
const light2dark = require('./light2dark');

const dark2light = Object.fromEntries(
  Object.entries(light2dark).map(([ key, val ]) => [ val, key ])
);

const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)').matches;

function initTheme() {

  document.documentElement.dataset.theme = localStorage.getItem('theme') ||
    (prefersDarkScheme ? 'dark' : 'light');

  if (document.documentElement.dataset.theme == 'dark') {
    // hide themed images until they're processed by onload handler
    document.head.insertAdjacentHTML('afterBegin', '<style> [data-use-theme] { visibility: hidden } </style>');
  }

  document.addEventListener('load', ({target}) => {
    if (target.tagName != 'OBJECT' || target.type != 'image/svg+xml') return;
    if (!target.hasAttribute('data-use-theme')) return;

    if (document.documentElement.dataset.theme == 'dark') {
      themeSvg(target, 'light', 'dark');
    }
  }, true);

}

if (document.documentElement.dataset.themeEnabled) {
  initTheme();
}

function themeSvg(object, oldTheme, newTheme) {
  // console.log(object.data, oldTheme, newTheme);
  let colorMapping = newTheme == 'dark' ? light2dark : dark2light;

  let svgDocument = object.getSVGDocument();

  if (!svgDocument) {
    // object is not loaded yet
    // happens if it's inside a collapsed <div>,
    // skip for now
    // will be loaded later (and themed on onload)
    return;
  }

  let replaceAttrs = ['fill', 'stroke', 'stop-color' /* for gradient */];

  for(let attr of replaceAttrs) {
    for(let elem of svgDocument.querySelectorAll(`[${attr}]`)) {
      let oldColor = normalizeColor(elem.getAttribute(attr));
      if (oldColor == 'none') continue;
      let newColor = colorMapping[oldColor];
      if (newColor) {
        elem.setAttribute(attr, newColor);
      } else {
        console.error(`No theme color mapping for attr ${attr}, value ${elem.getAttribute(attr)}`);
      }
    }
  }

  for(let elem of svgDocument.querySelectorAll('[style]')) {
    for(let attr of replaceAttrs) {
      if (elem.style[attr]) {
        let oldColor = normalizeColor(elem.style[attr]);
        if (oldColor == 'none') continue;
        let newColor = colorMapping[oldColor];
        if (newColor) {
          elem.style[attr] = colorMapping[newColor];
        } else {
          console.error(`No theme color mapping for attr ${attr}, value ${elem.style[attr]}`);
        }

      }
    }
  }

  object.style.visibility = 'visible';
}

function normalizeColor(color) {
  if (color[0] == '#' && color.length == 4) {
    let letters = color.slice(1).split('');
    color = '#' + letters[0] + letters[0] +letters[1] +letters[1] + letters[2] + letters[2];
  }
  return color.toLowerCase();
}

window.themeSvg = themeSvg;