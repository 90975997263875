module.exports = {
  "#c9dcea": "#293c40", // blue-12
  "#1c85b5": "#6bafbc", // blue-30
  "#166388": "#7fd3e4", // blue-46
  "#eef6f1": "#1b2b22", // green-10
  "#91c2a3": "#315841", // green-16
  "#478964": "#498e67", // green-30
  "#37664b": "#74b28d", // green-46
  "#f6f3f1": "#272727", // grey-10
  "#181717": "#ffffff", // grey-120
  "#d1cfcd": "#3f3f3e", // grey-13
  "#7e7c7b": "#82807f", // grey-30
  "#5e5c5c": "#a6a4a3", // grey-46
  "#767d89": "#7a818d", // grime-30
  "#c06334": "#7a7f9c", // orange-30
  "#fef1f0": "#441b1b", // red-10
  "#efa39f": "#8f2e32", // red-16
  "#d35155": "#d55759", // red-30
  "#a7333a": "#e88b88", // red-46
  "#ffffff": "#232529", // white
  "#fbf2ec": "#252732", // yellow-10
  "#dbaf88": "#4b4e65", // yellow-16
  "#af6e24": "#7a7f9c", // yellow-30
  "#643b0c": "#c4c6d4", // yellow-66
  // sometimes there's extra #000 in SVGS, no idea where it comes from
  // e.g. <use fill="#000" filter="url(#filter-3)" xlink:href="#path-2"/>
  // not sure this mapping matters, leaving it to avoid errors
  // #fffffe is same as #fff, just different to backmapping
  "#000000": "#fffffe"
};