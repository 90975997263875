module.exports = function () {
  let notification = document.querySelector('.notification_top');

  let id = notification.id;

  if (!id) {
    throw new Error('Top notification must have an id');
  }

  let topNotificationsHidden;
  try {
    topNotificationsHidden = JSON.parse(localStorage.topNotificationsHidden);
  } catch(e) {
    topNotificationsHidden = [];
  }

  // topNotificationHidden has the id of the hidden notification (current or previous one)
  if (topNotificationsHidden.includes(id)) {
    return;
  }

  notification.querySelector('button').onclick = () => {
    topNotificationsHidden.push(id);
    localStorage.topNotificationsHidden = JSON.stringify(topNotificationsHidden);

    notification.style.display = 'none';
    window.dispatchEvent(new CustomEvent('resize-internal'));
  };

  notification.style.display = '';
};
