

window.initSponsorBar = function() {
  let sponsorBar = document.getElementById('sponsorBar');

  if (!document.querySelector('.page').classList.contains('page_sidebar_on')) {
    return; // sidebar hidden
  }

  if (document.domain.includes('local') || document.domain.match(/^[0-9.]+$/)) {
    return; // localhost || 0.0.0.0
  }

  let display = true;
  let node = sponsorBar;
  while(node = node.parentElement) {
    if (getComputedStyle(node).display == 'none') {
      display = false;
      break;
    }
  }
  // false if not displayed (mobile?)
  if (display == false) {
    return;
  }

  // show ad
  let sponsorBarTitle = document.getElementById('sponsorBarTitle');
  let sponsorBarContent = document.getElementById('sponsorBarContent');

  let script = document.createElement('script');
  script.src = "https://cdn.carbonads.com/carbon.js?serve=CE7D42QJ&placement=javascriptinfo";
  script.id = '_carbonads_js';

  sponsorBarContent.append(script);

  let observer = new MutationObserver(mutationRecords => {
    for(let record of mutationRecords) {
      if (record.type !== 'childList') {
        return;
      }
      let children = [...sponsorBarContent.children].filter(child => child.tagName != 'SCRIPT');
      if (children.length && children[0].children.length) {
        // skip <script>
        // skip <div id="_bsa_viewable_check_577"></div>
        sponsorBarTitle.innerHTML = '<a href="http://carbonads.net/?utm_source=javascriptinfo&amp;utm_medium=ad_via_link&amp;utm_campaign=in_unit&amp;utm_term=carbon" class="carbon-poweredby" target="_blank" rel="noopener sponsored" style="color:var(--foregroundSubtle);display:inline">Ads by Carbon</a>';
      }
    }
  });

  observer.observe(sponsorBarContent, {
    childList: true,
    subtree: true
  });



};
