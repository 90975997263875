const VideoPlayerModal = require('./videoPlayerModal');

const delegate = require('client/delegate');

delegate(document.documentElement, '[data-video-player-href]', 'click', (e)  => {
  e.preventDefault();
  new VideoPlayerModal({
    src: e.target.dataset.videoPlayerHref
  });
});
