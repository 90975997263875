
try {
  window.localStorage._test = 1;
  delete window.localStorage._test;
} catch (e) {
  // localStorage disabled or forbidden
  try {
    window.localStorage = {};
    // so that operations on it won't fail
  } catch (e) {
    /* can happen TypeError: Attempted to assign to readonly property. */
  }
}
