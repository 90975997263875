let Modal = require('./modal');

// only use this gdpr stuff for en as of now
module.exports = function(callback) {

  function callbackOnce(res) {
    if (callbackOnce.triggered) {
      return;
    }
    callbackOnce.triggered = true;
    callback(res);
  }

  if (localStorage.gdprAccepted) {
    return callbackOnce(true);
  }

  let modal = new Modal();

  modal.setContent(`<div class="gdpr">
    <h1 class="gdpr__title">${__('site.gdpr_dialog.title')}</h1>
    <form class="gdpr__form">
      <p class="gdpr__text">${__('site.gdpr_dialog.text')}</p>
      
      <input class="button button_action" autofocus name="accept" type="submit" value="${__('site.gdpr_dialog.accept')}">
      <input class="button button_cancel" name="cancel" type="button" value="${__('site.gdpr_dialog.cancel')}">
      
    </form>
    </div>
  `);


  function accept() {
    localStorage.gdprAccepted = 1;
    modal.remove();
    callbackOnce(true);
  }

  function cancel() {
    modal.remove();
    callbackOnce(false);
  }


  modal.elem.querySelector('form').addEventListener('submit', event => {
    event.preventDefault();
    accept();
  });


  modal.elem.querySelector('form').elements.cancel.addEventListener('click', event => {
    event.preventDefault();
    cancel();
  });

  modal.elem.addEventListener('modal-remove', function () {
    // unless user did not accept this, false is triggered
    setTimeout(() => callbackOnce(false), 10);
  });

};
